import React, { useMemo } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const MotivazioniDocumenti = ({ data }) => {
  const { sectionTitle } = data;

  const contentValues = useMemo(
    () =>
      sectionTitle?.map((section) =>
        section?.content?.content?.value
          ?.replace(/@corrispettivoEnergia/, section?.content?.prodottoCRM?.corrispettivoEnergia)
          ?.replace(/@componentePrezzoFisso/, section?.content?.prodottoCRM?.componentePrezzoFisso)
          ?.replace(/@corrispettivoF1/, section?.content?.prodottoCRM?.corrispettivoF1)
          ?.replace(/@corrispettivoF2/, section?.content?.prodottoCRM?.corrispettivoF2)
          ?.replace(/@corrispettivoF3/, section?.content?.prodottoCRM?.corrispettivoF3)
          ?.replace(/@dataFineValidita/, section?.content?.prodottoCRM?.dataFineValidita)
          ?.replace(/@quotaFissaLuce/, section?.content?.prodottoCRM?.quotaFissaLuce)
          ?.replace(/@quotaFissaGas/, section?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@quotaVariabileGas/, section?.content?.prodottoCRM?.quotaVariabileGas)
          ?.replace(/@quotaVariabileLuce/, section?.content?.prodottoCRM?.quotaVariabileLuce)
          ?.replace(/@quotaAnnualeLuceFissa/, section?.content?.prodottoCRM?.quotaFissaLuceAnnuale)
          ?.replace(/@quotaAnnualeGasFissa/, section?.content?.prodottoCRM?.quotaFissaGasAnnuale)
          .replace(/@spreadF1/, section?.content?.prodottoCRM?.spreadF1)
          .replace(/@spreadF2/, section?.content?.prodottoCRM?.spreadF2)
          .replace(/@spreadF3/, section?.content?.prodottoCRM?.spreadF3)
          .replace(
            /@corrispettivoBiorariaF1/,
            section?.content?.prodottoCRM?.corrispettivoBiorariaF1
          )
          .replace(
            /@corrispettivoBiorariaF23/,
            section?.content?.prodottoCRM?.corrispettivoBiorariaF23
          )
          .replace(
            /@quotaCommFissaDomesticoGasMensile/,
            section?.content?.prodottoCRM?.quotaCommFissaDomesticoGasMensile
          )
          .replace(
            /@quotaCommFissaDomesticoGas/,
            section?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
          )
          .replace(
            /@quotaCommFissaDomesticoLuceMensile/,
            section?.content?.prodottoCRM?.quotaCommFissaDomesticoLuceMensile
          )
          .replace(
            /@quotaCommFissaDomesticoLuce/,
            section?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
          )
          .replace(
            /@quotaCommVariabileDomesticoGas/,
            section?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
          )
          .replace(
            /@quotaCommVariabileDomesticoLuce/,
            section?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            section?.content?.prodottoCRM?.spreadLuceLordoPerdite
          )
          ?.replace(/@spreadGas/g, section?.content?.prodottoCRM?.spreadGas)
      ),
    [sectionTitle]
  );
  return (
    <section className="section motivazioni-documenti wrapper-container">
      <div className="container-fluid">
        {sectionTitle?.map((section, i) => (
          <div className="row" key={i}>
            <div className="col-12">
              {section?.value && <p>{section.value}</p>}
              <div dangerouslySetInnerHTML={{ __html: contentValues[i] }}></div>
              {section?.content?.document?.map(
                (doc, j) =>
                  doc?.value && (
                    <p key={j}>
                      <a
                        href={
                          doc?.content?.placeholder?.value
                            ? doc?.content?.placeholder?.value
                                ?.replace(
                                  "@condizioniGenerali",
                                  sectionTitle[i]?.content?.prodottoCRM?.urlDocCondizioniGenerali
                                )
                                .replace(
                                  "@condizioniParticolari",
                                  sectionTitle[i]?.content?.prodottoCRM?.urlDocCondizioniParticolari
                                )
                            : doc?.content?.documentFile?.node?.publicURL
                        }
                        className="mb-1"
                        target="_blank"
                        rel="noreferrer"
                        title={doc.value}
                      >
                        PDF - {doc.value}
                      </a>
                    </p>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default withPreview(MotivazioniDocumenti);
export const fragment = graphql`
  fragment MotivazioniDocumentiFragment on LiferayMotivazioniDocumenti {
    liferayFields {
      siteId
      articleId
    }
    sectionTitle {
      value
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          quotaFissaLuce
          quotaFissaGas
          componentePrezzoFisso
          quotaVariabileGas
          quotaVariabileLuce
          quotaFissaLuceAnnuale
          quotaFissaGasAnnuale
          urlDocCondizioniGenerali
          urlDocCondizioniParticolari
          spreadF1
          spreadF2
          spreadF3
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoGasMensile
          quotaCommFissaDomesticoLuce
          quotaCommFissaDomesticoLuceMensile
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          spreadGas
          spreadLuceLordoPerdite
        }
        content {
          value
        }
        document {
          value
          content {
            placeholder {
              value
            }
            documentFile {
              node {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
